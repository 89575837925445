<template>
  <div>
    <!-- search input -->
    <section id="techops-categories">
      <b-card class="mt-n1">
        <b-progress
          v-if="searching"
          :value="barValue"
          max="100"
        />
        <b-overlay
          :show="searching"
          rounded="sm"
        >
          <b-row>
            <b-col>
              <statistic-card-vertical
                :statistic="filteredCounts('techtility')"
                statistic-title="Techtility"
                color="info"
                class="mb-0"
              />
            </b-col>
            <b-col>
              <statistic-card-vertical
                :statistic="filteredCounts('connectivity')"
                statistic-title="Connectivity"
                color="info"
                class="mb-0"
              />
            </b-col>
            <b-col>
              <statistic-card-vertical
                :statistic="filteredCounts('productivity')"
                statistic-title="Productivity"
                color="info"
                class="mb-0"
              />
            </b-col>
            <b-col>
              <statistic-card-vertical
                :statistic="filteredCounts('cloud')"
                statistic-title="Cloud"
                color="info"
                class="mb-0"
              />
            </b-col>
            <b-col>
              <statistic-card-vertical
                :statistic="filteredCounts('enterprise')"
                statistic-title="Enterprise"
                color="info"
                class="mb-0"
              />
            </b-col>
            <b-col
              cols="1"
            ><div
              style="
                  border-left: 2px dotted;
                  height: 80%;
                  margin-left: 50%;
                  margin-top: 5%;
                "
            /></b-col>
            <b-col>
              <statistic-card-vertical
                :statistic="customers.queryCount"
                statistic-title="Total"
                color="info"
                class="mb-0"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </section>
    <section id="cortavo-content">
      <client-table
        :data="customers.results"
        title="Cortavo Techtility"
        filter-type="techtility"
      />
      <client-table
        :data="customers.results"
        title="Cortavo Connectivity"
        filter-type="connectivity"
      />
      <client-table
        :data="customers.results"
        title="Cortavo Productivity"
        filter-type="productivity"
      />
      <client-table
        :data="customers.results"
        title="Cortavo Enterprise"
        filter-type="enterprise"
      />
      <client-table
        :data="customers.results"
        title="Virtual Private Servers"
        filter-type="cloud"
      />
    </section>
  </div>
</template>

<script>
import {
  BCard, BProgress, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import ClientTable from './CortavoClientTable.vue'

export default {
  components: {
    BCard,
    BProgress,
    BRow,
    BCol,
    BOverlay,
    StatisticCardVertical,
    ClientTable,
  },
  data() {
    return {
      timer: 0,
      timer2: 0,
      barValue: 0,
      searching: true,
      message: '',
      stats: {},
      customers: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            identifier: '',
            name: '',
            accountrep: 0,
            totalContacts: 0,
            seatCount: 0,
            state: '',
            industry: null,
            dateAcquired: '',
            endDate: '',
            lastBilled: '',
            isActive: true,
            companyType: '',
          },
        ],
      },
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) {
        this.barValue = 0
      }
      this.barValue += 5
    }, 600)
  },
  async created() {
    this.getCustomerList()
    this.timer2 = setInterval(() => {
      this.searching = true
      this.getCustomerList()
    }, 300000)
  },
  methods: {
    async getCustomerList() {
      this.searching = true
      await this.$http
        .get(
          'https://asi-api2.azure-api.net/psa/query/company?by=cortavoSummary&key=836715d7f31643618225af5af3141717',
        )
        .then(res => {
          this.customers = res.data
        })
      this.searching = false
    },
    filteredCounts(filterName) {
      return this.customers.results.filter(item => item.companyType.toLowerCase().includes(filterName)).length
    },
  },
}
</script>
