<template>
  <b-card
    v-if="filteredResults[0]"
    class="mt-n1"
  >
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card-header class="pt-0 pl-0">
        <b-card-title class="font-medium-1">
          {{ title }} - ({{ filteredResults.length }})
        </b-card-title>
      </b-card-header>
      <b-card-body class="font-medium-1 mb-0 pb-0">
        <b-table
          hover
          striped
          :items="filteredResults"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="mb-0"
          @row-clicked="rowClicked"
        ><template #cell(dateAcquired)="row">
           <div
             v-if="row.item.dateAcquired[1].length >= 3"
             class="text-center"
             style="min-width:100px"
           >
             {{ row.item.dateAcquired[1] | moment("YYYY-MM-DD") }}
           </div>
           <div
             v-if="row.item.dateAcquired[1].length <= 3"
             class="text-center"
             style="min-width:100px"
           >
             {{ row.item.dateAcquired | moment("YYYY-MM-DD") }}
           </div>
         </template>
          <template #cell(endDate)="row">
            <div
              class="text-center"
              style="min-width:100px"
            >
              {{ row.item.endDate | moment("YYYY-MM-DD") }}
            </div>
          </template>
          <template #cell(state)="row">
            <div class="text-center">
              {{ row.item.state }}
            </div>
          </template>
          <template #cell(seatCount)="row">
            <div
              class="text-center"
              style="min-width:72px"
            >
              {{ row.item.seatCount }}
            </div>
          </template>
          <template #cell(identifier)="row">
            <div class="text-center">
              {{ row.item.identifier }}
            </div>
          </template>
          <template #cell(totalContacts)="row">
            <div class="text-center">
              {{ row.item.totalContacts }}
            </div>
          </template>
          <template #cell(lastBilled)="row">
            <div
              class="text-center"
              style="min-width:100px"
            >
              {{ row.item.lastBilled | moment("YYYY-MM-DD") }}
            </div>
          </template>
          <template #cell(name)="row">
            <div
              style="min-width:350px"
              class="text-truncate"
            >
              {{ row.item.name }}
            </div>
          </template>
          <template #cell(industry)="row">
            <div
              style="min-width:200px"
              class="text-truncate"
            >
              {{ row.item.industry }}
            </div>
          </template>
          <template #cell(accountrep)="row">
            <div style="min-width:125px">
              {{ row.item.accountrep }}
            </div>
          </template>
          <template #cell(buildApprovedBy)="row">
            <div style="min-width:125px">
              {{ row.item.buildApprovedBy }}
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => {},
    },
    filterType: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      sortBy: 'identifier',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        { key: 'identifier', label: 'ID', sortable: true },
        { key: 'name', label: 'NAME', sortable: true },
        { key: 'totalContacts', label: 'USERS', sortable: true },
        { key: 'seatCount', label: 'MIN SEATS', sortable: true },
        { key: 'state', label: 'STATE', sortable: true },
        { key: 'industry', label: 'INDUSTRY', sortable: true },
        { key: 'dateAcquired', label: 'AQUIRED' },
      ],
    }
  },
  computed: {
    filteredResults() {
      const results = this.data.filter(item => item.companyType.toLowerCase().includes(this.filterType))
      return results
    },
  },
  watch: {
    data(val) {
      if (val.queryCount === 0) {
        this.show = true
      }
      if (val.queryCount > 0) {
        this.show = false
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    rowClicked(items) {
      this.$router.push({
        name: 'cortavo-view',
        params: { id: items.identifier },
      })
    },
  },
}
</script>
<style>
.width150px {
   width: 150px;
}
.width300px {
   width: 300px;
}
.width400px {
   width: 400px;
}
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
th {
  text-align: center;
}
</style>
